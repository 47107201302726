.root {
  --uui-icon_btn: var(--uui-color-50);
  --uui-icon_btn-hover: var(--uui-color-60);
  --uui-icon_btn-active: var(--uui-color-70);
  --uui-icon_btn-disabled: var(--uui-icon-disabled);
  --uui-icon_btn-focus-radius: var(--uui-focus-radius);
  fill: var(--uui-icon_btn);
}
.root:global(.uui-icon_button.uui-color-neutral) {
  --uui-icon_btn: var(--uui-icon);
  --uui-icon_btn-hover: var(--uui-icon-hover);
  --uui-icon_btn-active: var(--uui-icon-active);
}
.root:global(.-clickable):hover {
  fill: var(--uui-icon_btn-hover);
}
.root:global(.-clickable):active {
  fill: var(--uui-icon_btn-active);
}
.root:global(.-clickable):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: var(--uui-focus-outline-offset);
  border-radius: var(--uui-icon_btn-focus-radius);
}
.root:global(.uui-disabled) {
  fill: var(--uui-icon_btn-disabled);
}
.root:global(.uui-disabled):global(.-clickable):hover, .root:global(.uui-disabled):global(.-clickable):focus {
  fill: var(--uui-icon_btn-disabled);
}
.root:global(.uui-disabled):global(.-clickable):active {
  fill: var(--uui-icon_btn-disabled);
}