.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-dt-columns_config_modal-padding: 24px;
  --uui-dt-columns_config_modal-horizontal-gap: 6px;
  --uui-dt-columns_config_modal-search_area-min-height: 42px;
  --uui-dt-columns_config_modal-pin_icon_button-column-gap: 6px;
  --uui-dt-columns_config_modal-group_title-font-size: 14px;
  --uui-dt-columns_config_modal-group_title-line-height: 18px;
  --uui-dt-columns_config_modal-group_title-font-weight: 600;
  --uui-dt-columns_config_modal-subgroup-min-height: 24px;
  --uui-dt-columns_config_modal-subgroup_title-font-size: 10px;
  --uui-dt-columns_config_modal-subgroup_title-line-height: 12px;
  --uui-dt-columns_config_modal-subgroup_title-font-weight: 600;
  --uui-dt-columns_config_modal-no_data_title-line-height: 30px;
  --uui-dt-columns_config_modal-no_data_title-font-size: 24px;
  --uui-dt-columns_config_modal-no_data_title-font-weight: 600;
  --uui-dt-columns_config_modal-no_data_sub_title-line-height: 24px;
  --uui-dt-columns_config_modal-no_data_sub_title-font-size: 16px;
  --uui-dt-columns_config_modal-no_data_sub_title-font-weight: 400;
}
.root .main-panel {
  height: 100%;
}
.root .main-panel .group {
  padding: 0 var(--uui-dt-columns_config_modal-padding);
  column-gap: var(--uui-dt-columns_config_modal-horizontal-gap);
}
.root .main-panel .group .group-title {
  font-size: var(--uui-dt-columns_config_modal-group_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-group_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-group_title-font-weight);
}
.root .main-panel .group:not(:first-child) {
  padding-top: calc(var(--uui-grid-unit) * 2);
}
.root .main-panel .group-items {
  flex-direction: column;
}
.root .main-panel .no-data {
  flex-direction: column;
  top: calc(50% - 35px);
  position: relative;
}
.root .main-panel .no-data .no-data-title {
  font-size: var(--uui-dt-columns_config_modal-no_data_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-no_data_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-no_data_title-font-weight);
}
.root .main-panel .no-data .no-data-sub-title {
  font-size: var(--uui-dt-columns_config_modal-no_data_sub_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-no_data_sub_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-no_data_sub_title-font-weight);
}
.root .main-panel .h-divider {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: var(--uui-grid-unit) var(--uui-dt-columns_config_modal-padding);
}
.root .main-panel .h-divider::before {
  content: "";
  height: 1px;
  display: block;
  background: var(--uui-divider-light);
}
.root .search-area {
  min-height: var(--uui-dt-columns_config_modal-search_area-min-height);
  column-gap: var(--uui-dt-columns_config_modal-horizontal-gap);
  padding: 0 var(--uui-dt-columns_config_modal-padding);
  padding-bottom: var(--uui-grid-unit);
}
.root .subgroup-accordion :global(.uui-accordion-toggler):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
}
.root .subgroup-accordion .subgroup {
  cursor: pointer;
  text-transform: uppercase;
  min-height: var(--uui-dt-columns_config_modal-subgroup-min-height);
  padding: 0 var(--uui-dt-columns_config_modal-padding);
  column-gap: var(--uui-dt-columns_config_modal-horizontal-gap);
}
.root .subgroup-accordion .subgroup .subgroup-title {
  font-size: var(--uui-dt-columns_config_modal-subgroup_title-font-size);
  line-height: var(--uui-dt-columns_config_modal-subgroup_title-line-height);
  font-weight: var(--uui-dt-columns_config_modal-subgroup_title-font-weight);
}
.root .subgroup-accordion .subgroup :global(.uui-icon) {
  fill: var(--uui-text-tertiary);
}