.container {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
}
.container:focus-within {
  outline: none;
}
.container :global(.uui-radioinput) {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
.container :global(.uui-radioinput) input[type=radio] {
  margin: 0;
  border: 0 none;
  width: 0;
  height: 0;
  appearance: none;
}
.container :global(.uui-radioinput) input[type=radio]:focus {
  outline: none;
}
.container .circle {
  position: absolute;
  left: 0;
  top: 0;
}