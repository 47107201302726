.delete-button {
  color: var(--uui-critical-70);
}
.delete-button svg {
  fill: var(--uui-critical-70);
}

.tab-button :global(.uui-icon) {
  width: 18px;
  height: 18px;
}

.targetOpen:global(.uui-button-box) :global(.uui-icon) {
  visibility: visible;
}