.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-rdtp-date-bg-selected: var(--uui-info-10);
  --uui-rdtp-blocker-bg: color-mix(in srgb, var(--uui-surface-higher) 80%, transparent);
  --uui-rdtp-separator-width: 1px;
  --uui-rdtp-calendar-day-top: -3px;
  --uui-rdtp-gradient-size: 6px;
  --uui-rdtp-day-width: 40px;
  --uui-rdtp-selected-day-radius: 50%;
  --uui-rdtp-margin-right: 10px;
}
.root :global(.uui-calendar-days) > div:last-child :global(.uui-calendar-day-cell):last-child > :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) {
  position: relative;
}
.root :global(.uui-calendar-days) > div:last-child :global(.uui-calendar-day-cell):last-child > :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) :global(.uui-calendar-day) {
  top: var(--uui-rdtp-calendar-day-top);
}
.root :global(.uui-calendar-days) > div:last-child :global(.uui-calendar-day-cell):last-child > :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper))::after {
  background: linear-gradient(320deg, var(--uui-surface-main) 3px, transparent 0), linear-gradient(-135deg, var(--uui-surface-main) 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-y;
  background-size: var(--uui-rdtp-gradient-size) var(--uui-rdtp-gradient-size);
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--uui-rdtp-gradient-size);
  height: 100%;
}
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):empty + :global(.uui-calendar-day-cell) :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)),
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):first-child :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) {
  position: relative;
}
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):empty + :global(.uui-calendar-day-cell) :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) :global(.uui-calendar-day),
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):first-child :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper)) :global(.uui-calendar-day) {
  top: var(--uui-rdtp-calendar-day-top);
}
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):empty + :global(.uui-calendar-day-cell) :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper))::after,
.root :global(.uui-calendar-days) > div:first-child :global(.uui-calendar-day-cell):first-child :global(.uui-range-datepicker-in-range):not(:global(.uui-range-datepicker-first-day-in-range-wrapper)):not(:global(.uui-range-datepicker-last-day-in-range-wrapper))::after {
  background: linear-gradient(45deg, var(--uui-surface-main) 3px, transparent 0), linear-gradient(135deg, var(--uui-surface-main) 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-y;
  background-size: var(--uui-rdtp-gradient-size) var(--uui-rdtp-gradient-size);
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--uui-rdtp-gradient-size);
  height: 100%;
}
.root :global(.uui-range-datepicker-in-range):global(.uui-calendar-day-wrapper) {
  background-color: var(--uui-rdtp-date-bg-selected);
  border-radius: 0;
  width: var(--uui-rdtp-day-width);
}
.root :global(.uui-range-datepicker-first-day-in-range-wrapper):global(.uui-calendar-selected-day) {
  border-top-left-radius: var(--uui-rdtp-selected-day-radius);
  border-bottom-left-radius: var(--uui-rdtp-selected-day-radius);
}
.root :global(.uui-range-datepicker-last-day-in-range-wrapper):global(.uui-calendar-selected-day) {
  border-top-right-radius: var(--uui-rdtp-selected-day-radius);
  border-bottom-right-radius: var(--uui-rdtp-selected-day-radius);
  margin-right: var(--uui-rdtp-margin-right);
}
.root :global(.uui-range-datepicker-separator) {
  display: flex;
  flex: 0 0 auto;
  border-left: var(--uui-rdtp-separator-width) solid var(--uui-control-border);
}

.container.day-selection .from-picker :global(.uui-datepicker-header-nav-icon-right) {
  visibility: hidden;
}
.container.day-selection .to-picker :global(.uui-datepicker-header-nav-icon-left) {
  visibility: hidden;
}
.container .bodes-wrapper {
  position: relative;
  transition: height 0.3s ease-in-out;
}
.container .blocker {
  background-color: var(--uui-rdtp-blocker-bg);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

:global(.uui-datepicker-container) {
  transition: height 0.3s ease-in-out;
}