.root {
  --uui-count_indicator-bg: var(--uui-color-50);
  --uui-count_indicator-border: var(--uui-color-50);
  --uui-count_indicator-caption: var(--uui-color-contrast);
  --uui-count_indicator-font: var(--uui-font);
  --uui-count_indicator-font-weight: 600;
  --uui-count_indicator-height: var(--uui-size);
  --uui-count_indicator-min-width: var(--uui-size);
  --uui-count_indicator-border-width: var(--uui-border-width);
  --uui-count_indicator-border-radius: calc(var(--uui-size) / 2);
  --uui-count_indicator-horizontal-padding: calc(var(--uui-horizontal-padding) - var(--uui-count_indicator-border-width));
  --uui-count_indicator-font-size: var(--uui-font-size);
  --uui-count_indicator-line-height: var(--uui-line-height);
}
.root:global(.uui-count_indicator) {
  box-sizing: border-box;
  height: var(--uui-count_indicator-height);
  min-width: var(--uui-count_indicator-min-width);
  font-family: var(--uui-count_indicator-font);
  font-size: var(--uui-count_indicator-font-size);
  font-weight: var(--uui-count_indicator-font-weight);
  line-height: var(--uui-count_indicator-line-height);
  margin: auto 0;
  text-align: center;
  flex-shrink: 0;
  width: fit-content;
  background-color: var(--uui-count_indicator-bg);
  color: var(--uui-count_indicator-caption);
  border: var(--uui-count_indicator-border-width) solid var(--uui-count_indicator-border);
  border-radius: var(--uui-count_indicator-border-radius);
  padding-left: var(--uui-count_indicator-horizontal-padding);
  padding-right: var(--uui-count_indicator-horizontal-padding);
}