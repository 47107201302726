.container .labelWrapper {
  display: flex;
  align-items: center;
  column-gap: 3px;
}
.container :global(.uui-label) {
  display: flex;
  align-items: center;
}
.container :global(.uui-label) :global(.uui-labeled-input-info-icon) {
  display: flex;
}
.container .optional-field-wrapper {
  text-overflow: ellipsis;
}
.container :global(.uui-label-left) {
  display: flex;
  align-items: center;
}
.container .right-children-position {
  flex-basis: 0;
  flex-grow: 1;
}