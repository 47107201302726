/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 300" instead "$font-sans-light"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" instead "$font-sans"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 600" instead "$font-sans-semibold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 700" instead "$font-sans-bold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" & "font-style: italic" instead "$font-sans-italic"!
 */
/* Night scale */
.prefixWrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}