@keyframes skeleton_loading {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0);
  }
}
.root {
  --uui-text_placeholder: var(--uui-skeleton);
  --uui-text_placeholder-gradient: repeating-linear-gradient(90deg, var(--uui-neutral-40) 0, var(--uui-neutral-30) 50%, var(--uui-neutral-40) 100%);
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
}

.loading-word {
  position: relative;
  overflow: hidden;
  user-select: none;
  font-family: "Redacted", cursive;
  letter-spacing: -1px;
  color: var(--uui-text_placeholder);
  vertical-align: 1px;
}
.loading-word.animated-loading {
  color: transparent;
  background-clip: border-box;
  -webkit-background-clip: text;
}
.loading-word.animated-loading::before {
  content: "";
  position: absolute;
  width: 400px;
  height: 100%;
  top: 0;
  left: 0;
  background-image: var(--uui-text_placeholder-gradient);
  background-repeat: repeat;
  background-attachment: fixed;
  animation: skeleton_loading 2s ease infinite;
}