$shadow:
    0 3px 6px 0 rgba(29, 30, 38, 0.1),
    0 1px 3px 0 rgba(29, 30, 38, 0.1);

.container {
    // margin-top: 24px;
    display: flex;
    flex-direction: column;
    box-shadow: $shadow;
    gap: 10px;

    &:hover {
        .nav-card {
            background-size: 350%;
        }
    }

    .navCard {
        width: 220px;
        height: 220px;
        flex-wrap: wrap;
        transition: 1s;
        // background-size: 250%;
        // background-position: 0 0;
        color: #fff;

        .navCaption {
            position: absolute;
            left: 18px;
            bottom: 24px;
            color: #ffffff;
        }
        .title {
            margin: 18px 24px 0 24px;
            color: #fff;
        }

        .description {
            margin: -8px 24px 24px 24px;
            max-height: 155px;
            overflow-y: hidden;

            .descriptionText {
                color: #fff;
            }

            .itemsToAction {
            }
        }
    }

    .navDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 365px;
        background-color: var(--uui-surface-main);

        .title {
            margin: 18px 24px 0 24px;
        }

        .description {
            position: relative;
            margin: -8px 24px 24px 24px;
            max-height: 155px;
            overflow-y: hidden;
        }
    }
}
