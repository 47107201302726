.avatar-right {
  flex-direction: row-reverse;
}

.avatar-left {
  flex-direction: row;
}

.container > * {
  margin-right: var(--overlap);
}