.root {
  --uui-panel-bg: transparent;
  --uui-panel-shadow: var(--uui-shadow-level-1);
  --uui-panel-border-radius: var(--uui-border-radius);
  overflow: hidden;
  border-radius: var(--uui-panel-border-radius);
  background-color: var(--uui-panel-bg);
}
.root.margin-24 {
  margin: 24px;
}
.root.padding-12 {
  padding: 12px;
}
.root.padding-24 {
  padding: 24px;
}
.root.shadow {
  box-shadow: var(--uui-panel-shadow);
}
.root:global(.-clickable):hover {
  cursor: pointer;
}
.root.uui-surface-main {
  --uui-panel-bg: var(--uui-surface-main);
}