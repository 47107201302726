.root {
    --uui-tooltip-border-radius: 3px;
    --uui-tooltip-border: #474a59;
    // Override the default background
    --uui-main_menu-bg: transparent !important;

    .uui-button-box {
        min-height: 48px;
    }

    .uui-caption {
        padding: 15px 6px;
    }

    .linkContainer {
        display: flex;
        padding-inline-start: 9px;
        padding-inline-end: 9px;
        align-items: center;
        min-height: 60px;

        &:hover {
            background-color: var(--uui-neutral-90);
        }

        .linkCaption {
            padding-inline-start: 6px;
            color: var(--uui-main_menu-text);
            white-space: nowrap;
        }
    }

    .git-star-container {
        position: relative;
        display: flex;
        padding: 0;
        align-items: center;
        min-height: 60px;
        margin-inline-start: 8px;

        &:focus-visible {
            outline: none;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 11px;
            height: 11px;
            left: -6px;
            transform: translateY(0) rotate(45deg);
            background-color: var(--uui-main_menu-bg);
            border: 1px solid var(--uui-tooltip-border);
            border-top: 0 none;
            border-inline-end: 0 none;
            z-index: 1000;
            cursor: pointer;
            transition: 0.2s ease;
        }

        .wrapper {
            height: 24px;
            display: flex;
            padding: 3px 6px;
            align-items: center;
            border-radius: var(--uui-tooltip-border-radius);
            border: 1px solid var(--uui-tooltip-border);
            background-color: var(--uui-main_menu-bg);
            cursor: pointer;
            transition: 0.2s ease;

            svg {
                width: 12px;
                height: 100%;
                margin-inline-end: 3px;
                fill: var(--uui-main_menu-text);
            }

            .star-caption {
                color: var(--uui-main_menu-text);
            }
        }

        &:hover {
            &::after {
                border-color: lighten(#474a59, 10%);
            }

            .wrapper {
                border-color: lighten(#474a59, 10%);
            }
        }
    }

    .switcher {
        padding-left: 24px;
    }

    .icon {
        height: 60px;
        fill: var(--uui-main_menu-text);
        padding-inline-start: 9px;
        padding-inline-end: 9px;

        &:global(.uui-button-box):global(.uui-link_button):hover {
            fill: var(--uui-main_menu-text);
            background-color: var(--uui-neutral-90);
        }

        svg {
            width: 24px;
            height: 24px;
        }

        &.figma-icon {
            :global(.uui-icon):nth-of-type(2) {
                svg {
                    margin-inline-start: 3px;
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &.logo svg {
            width: 168px;
            height: 60px;
        }
    }

    .theme-switcher-button:hover {
        background-color: color-mix(in srgb, var(--white) 5%, transparent);
    }

    .theme-caption {
        :global(.uui-caption) {
            font-size: 14px;
        }
    }

    @media (max-width: 768px) {
        min-width: 354px;

        .theme-caption {
            :global(.uui-caption) {
                display: none;
            }
        }
    }
}

.headerPanel {
    background-image: url("../icons/header_1.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: 6px 12px;

    .headerTitle {
        margin-left: 10px;
        font-family: "Century Gothic", "Arial Narrow", Arial, sans-serif;
        color: white;
        font-weight: bold;
    }
}

// Menu buttons
.menuButton {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    width: 48px;
    height: 48px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    line-height: 48px;
    text-align: center;
    border-radius: 0px !important;
    margin: 0px 4px !important;
    min-height: 48px !important;
    max-height: 48px;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

.menuButton:hover {
    color: #424b51 !important; /* Override UUI hover color */
    background-color: #fff !important; /* Override UUI hover background */
}

.menuButton.active,
.menuButton.uui-active {
    background-color: #fff !important; /* Active background */
    color: #424b51 !important; /* Active text color */
    border-bottom: 3px solid var(--uui-main_menu-border-active) !important;
}

.green {
    background-color: #209042 !important;
    border: 1px solid #209042 !important;
}

.purple {
    background-color: #7f2090 !important;
    border: 1px solid #7f2090 !important;
}

.blue {
    background-color: #207a90 !important;
    border: 1px solid #207a90 !important;
}

.default {
    background-color: #424b51 !important;
    border: 1px solid #424b51 !important;
}

.active {
    background-color: #fff !important;
    color: #424b51 !important;
}
