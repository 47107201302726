.container {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  user-select: none;
}
.container .logo {
  display: flex;
  align-items: center;
  line-height: 0;
}
.container .itemsContainer > div {
  flex-grow: 1;
}