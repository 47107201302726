.root:global(.uui-shadow-top)::before, .root:global(.uui-shadow-bottom)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 200ms;
}
.root:global(.uui-shadow-top)::before {
  top: 0;
  z-index: 1;
}
.root:global(.uui-shadow-bottom)::after {
  bottom: 0;
}
.root:global(.uui-shadow-top-visible)::before {
  opacity: 1;
}
.root:global(.uui-shadow-bottom-visible)::after {
  opacity: 1;
}
.root :global(.uui-track-horizontal),
.root :global(.uui-track-vertical) {
  position: absolute;
  border-radius: 3px;
  z-index: 6;
}
.root :global(.uui-track-horizontal) {
  height: 6px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}
.root :global(.uui-track-vertical) {
  width: 6px;
  inset-inline-end: 2px;
  top: 2px;
  bottom: 2px;
}