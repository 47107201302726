.container {
  width: 100%;
}
.container:global(.uui-input-box) {
  display: flex;
  outline: none;
}
.container :global(.uui-input) {
  flex: 1 1 auto;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
}
.container :global(.uui-input)::-ms-clear {
  display: none;
}
.container :global(.uui-input)[type=search]::-webkit-search-decoration, .container :global(.uui-input)[type=search]::-webkit-search-cancel-button, .container :global(.uui-input)[type=search]::-webkit-search-results-button, .container :global(.uui-input)[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}