.container {
  cursor: pointer;
}
.container:global(.uui-input-box) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}
.container :global(.uui-input) {
  flex: 1 0 0;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  text-overflow: ellipsis;
}
.container :global(.uui-input).single-input {
  flex: 1 1 0;
}
.container :global(.uui-input).cursor-text {
  cursor: text;
}

.body {
  display: flex;
  width: 100%;
  overflow: hidden;
  outline: none;
  align-items: center;
}
.body:global(.uui-picker_toggler-multiline) {
  flex-wrap: wrap;
}

:global(.uui-picker_toggler-actions) {
  display: flex;
  flex-shrink: 0;
}

.toggler {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}