.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.date-input {
  min-width: 0;
  padding: var(--uui-rdtp-date_input-padding);
}
.date-input:global(.uui-input-box) {
  border-width: 0;
  height: var(--uui-rdtp-date_input-height);
}

.root {
  --uui-rdtp-bg: var(--uui-control-bg);
  --uui-rdtp-border: var(--uui-control-border);
  --uui-rdtp-border-hover: var(--uui-control-border-hover);
  --uui-rdtp-border-active: var(--uui-control-border-focus);
  --uui-rdtp-border-error: var(--uui-error-50);
  --uui-rdtp-border-radius: var(--uui-border-radius);
  --uui-rdtp-border-width: var(--uui-border-width);
  --uui-rdtp-date_input-height: calc(var(--uui-size) - var(--uui-border-width) * 2);
  --uui-rdtp-date_input-padding: 0 5px;
  display: flex;
  min-width: 0;
  background-color: var(--uui-rdtp-bg);
  border: var(--uui-rdtp-border-width) solid var(--uui-rdtp-border);
  border-radius: var(--uui-rdtp-border-radius);
}
.root > :first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.root > :last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.root .separator {
  display: flex;
  flex-shrink: 0;
  background: var(--uui-rdtp-border);
  width: var(--uui-rdtp-border-width);
}
.root:hover {
  border-color: var(--uui-rdtp-border-hover);
}
.root:hover .separator {
  background: var(--uui-rdtp-border-hover);
}
.root:hover:global(.uui-disabled), .root:hover:global(.uui-readonly) {
  border-color: var(--uui-rdtp-border);
}
.root:hover:global(.uui-disabled) .separator, .root:hover:global(.uui-readonly) .separator {
  background: var(--uui-rdtp-border);
}
.root:global(.uui-focus) {
  border-color: var(--uui-rdtp-border);
}
.root:global(.uui-focus) .separator {
  background: var(--uui-rdtp-border-active);
}
.root:global(.uui-focus) .date-input:hover {
  outline: var(--uui-rdtp-border-width) solid var(--uui-rdtp-border-hover);
}
.root:global(.uui-focus) .date-input:global(.uui-focus) {
  outline: var(--uui-rdtp-border-width) solid var(--uui-rdtp-border-active);
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--uui-control-bg-disabled);
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly) .date-input:global(.uui-focus) {
  box-shadow: none;
}
.root:global(.uui-invalid) {
  border-color: var(--uui-rdtp-border-error);
}
.root:global(.uui-invalid) .separator {
  background: var(--uui-rdtp-border-error);
}