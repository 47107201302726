.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-dt-header-cell-font: var(--uui-font);
  --uui-dt-header-cell-font-weight: 600;
  --uui-dt-header-cell-line-height: 30px;
  --uui-dt-header-cell-height: var(--uui-size);
  --uui-dt-header-cell-caption-column-gap: 3px;
  --uui-dt-header-cell-truncate-caption-line-height: 18px;
  color: var(--uui-text-primary);
  align-items: center;
  padding-inline-start: var(--uui-dt-header-cell-padding-start);
  padding-inline-end: var(--uui-dt-header-cell-padding-end);
  width: 0;
  background-clip: padding-box;
  height: var(--uui-dt-header-cell-height);
}
.root .caption-wrapper {
  column-gap: var(--uui-dt-header-cell-caption-column-gap);
}
.root :global(.uui-icon) svg {
  height: var(--uui-dt-header-cell-icon-size);
  width: inherit;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.-clickable):hover {
  background: var(--uui-dt-row-bg-hover);
  box-shadow: inset 1px 0 0 var(--uui-dt-border), inset -1px 0 0 var(--uui-dt-border);
}
.root:global(.-clickable):hover:not(:has(:global(.-clickable):hover)) .sort-icon, .root:global(.-clickable):hover:not(:has(:global(.-clickable):hover)) .dropdown-icon, .root:global(.-clickable):hover:not(:has(:global(.-clickable):hover)) .infoIcon {
  fill: var(--uui-icon-hover);
}
.root.resizable:hover {
  box-shadow: inset 1px 0 0 var(--uui-dt-border), inset -1px 0 0 var(--uui-dt-border);
}
.root .caption-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1 1 0;
  min-width: 0;
}
.root .caption-wrapper.align-right {
  flex-direction: row-reverse;
}
.root .caption-wrapper.align-center {
  justify-content: center;
}
.root .caption {
  --uui-text-line-height: var(--uui-dt-header-cell-line-height);
  font-family: var(--uui-dt-header-cell-font);
  font-weight: var(--uui-dt-header-cell-font-weight);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}
.root .caption.truncate {
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable */
  overflow: hidden;
  white-space: normal;
  line-height: var(--uui-dt-header-cell-truncate-caption-line-height);
}
.root .checkbox {
  padding: 0;
  padding-inline-end: var(--uui-horizontal-gap);
}
.root .icon {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}
.root .fold-all-icon {
  display: flex;
  padding: 0 var(--uui-horizontal-gap) 0 0;
  align-self: stretch;
}
.root .fold-all-icon:hover {
  fill: var(--uui-icon-hover);
}
.root .cell-tooltip {
  top: 0;
}
.root .upper-case {
  text-transform: uppercase;
}
.root .resizing-marker {
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  cursor: col-resize;
  user-select: none;
  width: var(--uui-dt-header-cell-resizing-marker-width);
}
.root .resizing-marker:hover {
  background-color: var(--uui-dt-cell-bg-resize);
}
.root.pinned-right .resizing-marker {
  inset-inline-start: 0;
}
.root.draggable {
  cursor: pointer;
}
.root.ghost {
  box-shadow: var(--uui-shadow-level-3);
  background-color: var(--uui-dt-row-bg-hover);
}
.root.is-dragged-out {
  background-color: var(--uui-dt-row-bg-hover);
  opacity: 0.5;
}
.root.dnd-marker-left {
  box-shadow: inset 2px 0 0 0 var(--uui-dt-header-drop_marker);
}
.root.dnd-marker-left:global(.-clickable), .root.dnd-marker-left:local(.resizable) {
  box-shadow: inset 2px 0 0 0 var(--uui-dt-header-drop_marker);
}
.root.dnd-marker-right {
  box-shadow: inset -2px 0 0 0 var(--uui-dt-header-drop_marker);
}
.root.dnd-marker-right:global(.-clickable), .root.dnd-marker-right:local(.resizable) {
  box-shadow: inset -2px 0 0 0 var(--uui-dt-header-drop_marker);
}

.cell-tooltip-wrapper {
  --uui-dt-header-cell-tooltip-caption-font-size: 14px;
  --uui-dt-header-cell-tooltip-caption-font-weight: 600;
  --uui-dt-header-cell-tooltip-info-font-size: 12px;
  --uui-dt-header-cell-tooltip-info-font-weight: 400;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: var(--uui-vertical-gap);
}
.cell-tooltip-wrapper .cell-tooltip-text {
  color: var(--uui-tooltip-text);
  padding: 0;
}
.cell-tooltip-wrapper .tooltip-caption {
  font-size: var(--uui-dt-header-cell-tooltip-caption-font-size);
  font-weight: var(--uui-dt-header-cell-tooltip-caption-font-weight);
}
.cell-tooltip-wrapper .tooltip-info {
  font-size: var(--uui-dt-header-cell-tooltip-info-font-size);
  font-weight: var(--uui-dt-header-cell-tooltip-info-font-weight);
}