.root {
  border: 0 none;
  margin: 0;
  padding: 0;
}
.root:global(.uui-horizontal-direction) {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 18px;
}
.root:global(.uui-vertical-direction) > * {
  margin-bottom: 12px;
}
.root:global(.uui-vertical-direction) :last-child {
  margin-bottom: 0;
}