.root {
  --uui-drop_marker: var(--uui-primary-50);
  --uui-drop_marker-offset: -1px;
  --uui-drop_marker-width: 2px;
}

.blocker {
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.marker {
  position: absolute;
  pointer-events: none;
  z-index: 10;
}

.top {
  top: var(--uui-drop_marker-offset);
  left: 0;
  right: 0;
  height: var(--uui-drop_marker-width);
  background-color: var(--uui-drop_marker);
}

.bottom {
  bottom: var(--uui-drop_marker-offset);
  left: 0;
  right: 0;
  height: var(--uui-drop_marker-width);
  background-color: var(--uui-drop_marker);
}

.left {
  top: 0;
  bottom: 0;
  left: var(--uui-drop_marker-offset);
  width: var(--uui-drop_marker-width);
  background-color: var(--uui-drop_marker);
}

.right {
  top: 0;
  bottom: 0;
  right: var(--uui-drop_marker-offset);
  width: var(--uui-drop_marker-width);
  background-color: var(--uui-drop_marker);
}

.inside {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: var(--uui-drop_marker-width) solid var(--uui-drop_marker);
  background-color: color-mix(in srgb, var(--uui-surface-highest) 50%, transparent);
}