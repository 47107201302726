body,
html {
    height: 100%;
}

body {
    margin: 0;
    display: flex;
    flex-direction: column;
    background: var(--white);

    &:global(.uui-theme-vanilla_thunder) {
        --font-inter: "Inter", sans-serif;
        --font-museo-sans: "Museo Sans", "Source Sans Pro", Arial, sans-serif;
    }
}

:global(#root) {
    flex: 1 0 auto;
}
