.root {
  --uui-progress_bar-bg: var(--uui-secondary-20);
  --uui-progress_bar-progress-bg: var(--uui-primary-50);
  --uui-progress_bar-label: var(--uui-neutral-0);
  --uui-progress_bar-top-label: var(--uui-neutral-80);
  --uui-progress_bar-border-radius: var(--uui-border-radius);
  --uui-progress_bar-font: var(--uui-font);
  --uui-progress_bar-font-weight: 400;
  background-color: var(--uui-progress_bar-bg);
  font-family: var(--uui-progress_bar-font);
  font-weight: var(--uui-progress_bar-font-weight);
  color: var(--uui-progress_bar-label);
  border-radius: var(--uui-progress_bar-border-radius);
}
.root :global(.bar) {
  background-color: var(--uui-progress_bar-progress-bg);
}
.root :global(.topLabel) {
  color: var(--uui-progress_bar-top-label);
}
.root.striped :global(.bar) {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  animation: animate-stripes 1s linear infinite;
}

@keyframes animate-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.size-12 {
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}

.size-18 {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
}

.size-24 {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
}