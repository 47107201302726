.root {
  --uui-rating-icon-filled: var(--uui-warning-50);
  --uui-rating-icon-empty: var(--uui-icon-disabled);
  --uui-rating-icon-disabled: var(--uui-icon);
  --uui-rating-icon-size: var(--uui-size);
  fill: var(--uui-rating-icon-filled);
}
.root svg {
  height: var(--uui-rating-icon-size);
  width: var(--uui-rating-icon-size);
}
.root :global(.uui-rating-star) {
  padding: 0 1px;
}
.root :global(.uui-rating-empty-star-container) svg {
  fill: var(--uui-rating-icon-empty);
}
.root:global(.uui-disabled) :global(.uui-rating-star) {
  fill: var(--uui-rating-icon-disabled);
  cursor: default;
}

.tooltip {
  pointer-events: none;
}