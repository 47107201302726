.container {
  pointer-events: none;
}
.container[data-placement^=top] .tooltip-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container[data-placement^=bottom] .tooltip-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.container[data-placement^=left] .tooltip-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container[data-placement^=right] .tooltip-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}