.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-filters_toggler-bg: var(--uui-control-bg);
  --uui-filters_toggler-bg-hover: var(--uui-neutral-5);
  --uui-filters_toggler-border: var(--uui-control-border);
  --uui-filters_toggler-border-hover: var(--uui-control-border-hover);
  --uui-filters_toggler-icon: var(--uui-icon);
  --uui-filters_toggler-label: var(--uui-text-tertiary);
  --uui-filters_toggler-shadow: none;
  --uui-filters_toggler-text: var(--uui-text-primary);
  --uui-filters_toggler-border-radius: var(--uui-border-radius);
  --uui-filters_toggler-font: var(--uui-font);
  --uui-filters_toggler-font-weight: 400;
}
.root:global(.uui-input-box) {
  box-sizing: border-box;
  min-width: 40px;
  position: relative;
  display: flex;
  outline: none;
  border: 1px solid var(--uui-filters_toggler-border);
  border-radius: var(--uui-filters_toggler-border-radius);
  background-color: var(--uui-filters_toggler-bg);
  box-shadow: var(--uui-filters_toggler-shadow);
  padding-left: 6px;
  padding-right: 6px;
}
.root:global(.uui-input-box):global(.-clickable):hover {
  cursor: pointer;
}
.root:global(.uui-input-box):hover, .root:global(.uui-input-box):focus {
  border-color: var(--uui-filters_toggler-border-hover);
  background-color: var(--uui-filters_toggler-bg-hover);
}
.root:global(.uui-input-box):global(.uui-opened) {
  border-color: var(--uui-filters_toggler-border-hover);
  background-color: var(--uui-filters_toggler-bg-hover);
}
.root:global(.uui-input-box) :global(.uui-icon-dropdown) {
  fill: var(--uui-filters_toggler-icon);
}
.root:global(.uui-input-box) .title-wrapper {
  font-family: var(--uui-filters_toggler-font);
  font-weight: var(--uui-filters_toggler-font-weight, normal);
  flex: 1 1 auto;
  min-width: 0;
  outline: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
}
.root:global(.uui-input-box) .title-wrapper .title {
  display: flex;
  text-overflow: initial;
  overflow: visible;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--uui-filters_toggler-label);
}
.root:global(.uui-input-box) .title-wrapper .text-wrapper {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  width: 100%;
  padding-left: 3px;
}
.root:global(.uui-input-box) .title-wrapper .selection {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) .title-wrapper .selection div {
  display: inline-block;
  margin-right: 3px;
}
.root:global(.uui-input-box) .title-wrapper .selection div :nth-last-child {
  margin-right: 0;
}
.root:global(.uui-input-box) .title-wrapper .selection + span {
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) .title-wrapper .postfix {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6px;
  overflow: visible;
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box) .title-wrapper .selected {
  color: var(--uui-filters_toggler-text);
}
.root:global(.uui-input-box):global(.size-24) {
  padding-left: 2px;
  padding-right: 2px;
}
.root:global(.uui-input-box):global(.size-24) .title-wrapper {
  padding-left: 3px;
  padding-right: 3px;
}
.root:global(.uui-input-box):global(.size-24):global(.uui-button-box), .root:global(.uui-input-box):global(.size-24):global(.uui-input-box) {
  min-height: 24px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-24) :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root:global(.uui-input-box):global(.size-30) {
  padding-left: 5px;
  padding-right: 5px;
}
.root:global(.uui-input-box):global(.size-30):global(.uui-button-box), .root:global(.uui-input-box):global(.size-30):global(.uui-input-box) {
  min-height: 30px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-30) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global(.uui-input-box):global(.size-36) {
  padding-left: 5px;
  padding-right: 5px;
}
.root:global(.uui-input-box):global(.size-36):global(.uui-button-box), .root:global(.uui-input-box):global(.size-36):global(.uui-input-box) {
  min-height: 36px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-36) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global(.uui-input-box):global(.size-42) {
  padding-left: 11px;
  padding-right: 11px;
}
.root:global(.uui-input-box):global(.size-42):global(.uui-button-box), .root:global(.uui-input-box):global(.size-42):global(.uui-input-box) {
  min-height: 42px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-42) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global(.uui-input-box):global(.size-48) {
  padding-left: 11px;
  padding-right: 11px;
}
.root:global(.uui-input-box):global(.size-48):global(.uui-button-box), .root:global(.uui-input-box):global(.size-48):global(.uui-input-box) {
  min-height: 48px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-48) :global(.uui-icon) svg {
  height: 24px;
  width: inherit;
}