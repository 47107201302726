.preset-input-cell {
  margin-top: auto;
  margin-bottom: auto;
}
.preset-input-cell .preset-input {
  padding-left: 3px;
  padding-right: 3px;
  width: 213px;
}
.preset-input-cell .preset-input :global(.uui-icon-accept),
.preset-input-cell .preset-input :global(.uui-icon-cancel) {
  width: 30px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preset-input-cell .preset-input :global(.uui-icon-accept):global(.-clickable) {
  background-color: var(--uui-accent-50);
  border-radius: var(--uui-border-radius);
  fill: var(--uui-accent-contrast);
}
.preset-input-cell .preset-input :global(.uui-icon-accept):global(.-clickable):hover {
  fill: var(--uui-accent-contrast);
  background-color: var(--uui-accent-60);
}
.preset-input-cell .preset-input :global(.uui-icon-accept):global(.-clickable):active {
  fill: var(--uui-accent-contrast);
  background-color: var(--uui-accent-70);
}
.preset-input-cell .preset-input :global(.uui-icon-cancel) {
  background-color: var(--uui-surface-main);
  border: 1px solid var(--uui-secondary-50);
  border-radius: var(--uui-border-radius);
  margin-left: 1px;
  box-sizing: border-box;
}
.preset-input-cell .preset-input :global(.uui-icon-cancel):hover {
  background-color: var(--uui-secondary-10);
}
.preset-input-cell .preset-input :global(.uui-icon-cancel):active {
  background-color: var(--uui-secondary-20);
}