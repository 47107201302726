.root {
  --uui-picker_item-horizontal-gap: 12px;
}
.root .column-gap {
  column-gap: var(--uui-picker_item-horizontal-gap);
}
.root .multiline {
  padding-top: var(--uui-picker_item-vertical-padding);
  padding-bottom: var(--uui-picker_item-vertical-padding);
}
.root .multiline .text {
  padding: 0;
}