.container:global(.uui-year_selection-container) :global(.uui-year_selection-content) {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.container:global(.uui-year_selection-container) :global(.uui-year_selection-years-container) {
  display: flex;
  flex-direction: column;
}
.container:global(.uui-year_selection-container) :global(.uui-year_selection-years-container) > * {
  display: flex;
}
.container:global(.uui-year_selection-container) :global(.uui-year_selection-year) {
  display: flex;
  justify-content: center;
  align-items: center;
}