@use "~@epam/promo/assets/styles/colors" as *;
@use "~@epam/promo/assets/styles/fonts" as *;

.wrapper {
    overflow: hidden;
    display: flex;
    position: relative;
}

body {
    &:global(.full-screen) {
        .container {
            height: 100vh;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 96px);
    transition: all 500ms;
    flex-basis: auto;
    min-width: 0;
    flex-grow: 1;

    :global(.uui-table-row) {
        .detailedIcon {
            visibility: hidden;
        }

        &:hover {
            .detailedIcon {
                visibility: visible;
            }
        }
    }
}

.presetsPanel {
    box-sizing: border-box;
    padding: 6px 12px;

    .presetsTitle {
        margin-left: 10px;
        font-family: "Century Gothic", "Arial Narrow", Arial, sans-serif;
        color: white;
        font-weight: bold;
    }
}

.iconContainer {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    border-right: 1px solid var(--uui-divider-light);
}

.icon {
    &:global(.uui-button-box) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.presets {
    padding-left: 50px;
    transition: 0.5s padding-left;
    background-color: var(--uui-surface-main);

    &-with-filter {
        padding-left: 0;
    }
}

.riskHeaderCell {
    background-color: #424b51;
    color: var(--white) !important;
}
.riskControlHeaderCell {
    background-color: #209042;
    color: var(--white) !important;
    // --uui-dt-header-cell-icon-size: 18px;
    // --uui-dt-header-cell-padding-start: 6px;
    // --uui-dt-header-cell-padding-end: 6px;
    // --uui-dt-header-cell-resizing-marker-width: 6px;

    // display: flex;
    // justify-content: flex-start; /* Align content to the start */
    // overflow: hidden;
    .captionWrapper {
        // display: flex;
        // overflow: hidden;
        // flex: 1 1;
        // min-width: 0;

        .caption {
            padding: 0 6px;
            // --uui-text: var(--white);
            color: var(--white);
            text-transform: uppercase;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
        }
    }
}

.detailedActions {
    display: flex;
    flex-direction: column; // Stacks the icons vertically
    justify-content: center;
    align-items: center;
    gap: 8px; // Space between icons

    .detailedIcon {
        cursor: pointer;
        transition:
            transform 0.5s ease,
            color 0.2s ease;

        &:hover {
            transform: scale(1.2); // Slightly enlarge on hover
            color: #0073e6; // Change the color on hover
        }
    }
}
