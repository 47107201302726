.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 600px;
  width: 100%;
  background-color: var(--uui-surface-main);
}

:global(.uui-error-content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 30px;
}

:global(.uui-error-image) {
  flex: 1 1 300px;
  width: 640px;
  max-width: 100%;
}

:global(.uui-error-title) {
  font-family: var(--uui-font);
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-top: 60px;
  color: var(--uui-text-primary);
  max-width: 700px;
}

:global(.uui-error-subtitle) {
  font-family: var(--uui-font);
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: var(--uui-text-primary);
  max-width: 700px;
}

@media screen and (max-width: 720px) {
  :global(.uui-error-image) {
    flex: 1 1 200px;
    width: 400px;
  }
  :global(.uui-error-title) {
    font-family: var(--uui-font);
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    margin-top: 48px;
    max-width: 460px;
  }
  :global(.uui-error-subtitle) {
    font-family: var(--uui-font);
    font-weight: 400;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    color: var(--uui-text-primary);
    max-width: 460px;
  }
}