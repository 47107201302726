.filterPanelWrapper {
    padding: 12px 24px 6px 24px;
    background-color: var(--uui-surface-main);
    border-bottom: 1px solid var(--uui-divider);

    .filterPanel {
        flex-wrap: wrap;
        padding-right: 12px;
        width: calc(100% - 295px);

        & > * {
            margin: 0 6px 6px 0;
        }
    }

    .search {
        align-self: flex-start;
    }
}
