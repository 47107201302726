.root {
  --uui-switch-bg: var(--uui-neutral-40);
  --uui-switch-bg-checked: var(--uui-color-50);
  --uui-switch-bg-hover: var(--uui-control-bg-hover);
  --uui-switch-bg-checked-hover: var(--uui-color-60);
  --uui-switch-bg-checked-disabled: var(--uui-color-20);
  --uui-switch-bg-disabled: var(--uui-control-bg-disabled);
  --uui-switch-border: var(--uui-control-border);
  --uui-switch-border-hover: var(--uui-control-bg-hover);
  --uui-switch-border-checked: var(--uui-color-50);
  --uui-switch-border-checked-hover: var(--uui-color-60);
  --uui-switch-border-checked-disabled: var(--uui-color-20);
  --uui-switch-border-disabled: var(--uui-control-border-disabled);
  --uui-switch-toggler-bg: var(--uui-neutral-0);
  --uui-switch-toggler-bg-disabled: var(--uui-control-bg-disabled);
  --uui-switch-toggler-border-checked: var(--uui-color-50);
  --uui-switch-toggler-border-hover: var(--uui-control-bg-hover);
  --uui-switch-toggler-border-disabled: var(--uui-control-border-disabled);
  --uui-switch-toggler-border-checked-hover: var(--uui-color-60);
  --uui-switch-toggler-border-checked-disabled: var(--uui-color-20);
  --uui-switch-label: var(--uui-text-primary);
  --uui-switch-label-disabled: var(--uui-text-disabled);
  --uui-switch-font: var(--uui-font);
  --uui-switch-font-weight: 400;
  --uui-switch-min-height: var(--uui-size);
  --uui-switch-min-width: var(--uui-size);
  --uui-switch-negative-min-width: calc(var(--uui-size) * -1);
  --uui-switch-border-width: var(--uui-border-width);
  --uui-switch-horizontal-gap: var(--uui-horizontal-gap);
  --uui-switch-line-height: var(--uui-line-height);
  --uui-switch-font-size: var(--uui-font-size);
  column-gap: var(--uui-switch-horizontal-gap);
}
.root :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(var(--uui-switch-min-width));
}
.root :global(.uui-switch-body) {
  height: var(--uui-switch-min-height);
  width: calc(var(--uui-switch-min-width) * 2);
  background-color: var(--uui-switch-bg);
  border: var(--uui-switch-border-width) solid var(--uui-switch-border);
  border-radius: var(--uui-switch-min-height);
  box-sizing: border-box;
}
.root :global(.uui-switch-body) :global(input[type="checkbox"]):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: var(--uui-focus-outline-offset);
  border-radius: var(--uui-switch-min-height);
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0;
}
.root :global(.uui-switch-toggler) {
  height: var(--uui-switch-min-height);
  width: var(--uui-switch-min-width);
  left: calc(0px - var(--uui-switch-border-width));
  border-radius: 50%;
  box-sizing: border-box;
  background-color: var(--uui-switch-toggler-bg);
  border: var(--uui-switch-border-width) solid var(--uui-switch-border);
  transition: 0.1s transform cubic-bezier(0.4, 0, 0.2, 1);
}
.root :global(.uui-input-label) {
  font-size: var(--uui-switch-font-size);
  line-height: var(--uui-switch-line-height);
  font-family: var(--uui-switch-font);
  font-weight: var(--uui-switch-font-weight, normal);
  color: var(--uui-switch-label);
}
.root:hover :global(.uui-switch-body), .root:focus-within :global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-hover);
  border-color: var(--uui-switch-border-hover);
}
.root:hover :global(.uui-switch-toggler), .root:focus-within :global(.uui-switch-toggler) {
  border-color: var(--uui-switch-toggler-border-hover);
}
.root:hover :global(.uui-checked):global(.uui-switch-body), .root:focus-within :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-checked-hover);
  border-color: var(--uui-switch-border-checked-hover);
}
.root:hover :global(.uui-checked) :global(.uui-switch-toggler), .root:focus-within :global(.uui-checked) :global(.uui-switch-toggler) {
  border-color: var(--uui-switch-toggler-border-checked-hover);
}
.root :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-checked);
  border: 1px solid var(--uui-switch-border-checked);
}
.root :global(.uui-checked) :global(.uui-switch-toggler) {
  border: 1px solid var(--uui-switch-toggler-border-checked);
}
.root:global(.uui-readonly),
.root:global(.uui-readonly) :global(.uui-switch-toggler),
.root:global(.uui-readonly) :global(.uui-switch-body),
.root:global(.uui-readonly) :global(.uui-input-label), .root:global(.uui-disabled),
.root:global(.uui-disabled) :global(.uui-switch-toggler),
.root:global(.uui-disabled) :global(.uui-switch-body),
.root:global(.uui-disabled) :global(.uui-input-label) {
  cursor: default;
}
.root:global(.uui-readonly) :global(.uui-switch-body), .root:global(.uui-disabled) :global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-disabled);
  border: 1px solid var(--uui-switch-border-disabled);
}
.root:global(.uui-readonly) :global(.uui-switch-toggler), .root:global(.uui-readonly):hover :global(.uui-switch-toggler), .root:global(.uui-readonly):focus-within :global(.uui-switch-toggler), .root:global(.uui-disabled) :global(.uui-switch-toggler), .root:global(.uui-disabled):hover :global(.uui-switch-toggler), .root:global(.uui-disabled):focus-within :global(.uui-switch-toggler) {
  background-color: var(--uui-switch-toggler-bg-disabled);
  border-color: var(--uui-switch-toggler-border-disabled);
}
.root:global(.uui-readonly) :global(.uui-checked):global(.uui-switch-body), .root:global(.uui-disabled) :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-checked-disabled);
  border: 1px solid var(--uui-switch-border-checked-disabled);
}
.root:global(.uui-readonly) :global(.uui-checked) :global(.uui-switch-toggler), .root:global(.uui-disabled) :global(.uui-checked) :global(.uui-switch-toggler) {
  background-color: var(--uui-switch-toggler-bg-disabled);
  border-color: var(--uui-switch-toggler-border-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--uui-switch-label-disabled);
}

*[dir=rtl] .root :global(.uui-switch-toggler), .root[dir=rtl] :global(.uui-switch-toggler) {
  right: calc(0px - var(--uui-switch-border-width));
}

*[dir=rtl] .root :global(.uui-checked) :global(.uui-switch-toggler), .root[dir=rtl] :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(var(--uui-switch-negative-min-width));
}