.root {
  --uui-burger_group-text: var(--uui-text-disabled);
  --uui-burger_group-line: var(--uui-text-secondary);
  --uui-burger_group-font: var(--uui-font);
  --uui-burger_group-font-weight: 400;
}

.group-header {
  padding: 17px 0 0 0;
}

.group-name {
  font-family: var(--uui-burger_group-font);
  font-weight: var(--uui-burger_group-font-weight);
  font-size: 12px;
  color: var(--uui-burger_group-text);
  background: var(--uui-burger_menu-item);
  padding: 0 14px 0 18px;
}

.line {
  position: absolute;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: var(--uui-burger_group-line);
  z-index: -1;
  margin-top: 11px;
}