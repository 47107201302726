/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 300" instead "$font-sans-light"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" instead "$font-sans"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 600" instead "$font-sans-semibold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 700" instead "$font-sans-bold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" & "font-style: italic" instead "$font-sans-italic"!
 */
/* Night scale */
.root {
  --uui-input_addon-bg: var(--uui-surface-higher);
  --uui-input_addon-text: var(--uui-text-tertiary);
  --uui-input_addon-font: var(--uui-font);
  --uui-input_addon-font-weight: 400;
  background-color: var(--uui-input_addon-bg);
  border: 1px solid var(--uui-control-border);
  color: var(--uui-input_addon-text);
  padding: 6px;
  font-size: 14px;
  font-family: var(--uui-input_addon-font);
  font-weight: var(--uui-input_addon-font-weight);
}