.container:global(.uui-calendar-container) :global(.uui-calendar-content) {
  display: flex;
  flex-direction: column;
}
.container:global(.uui-calendar-container) :global(.uui-calendar-weekdays-container) {
  display: flex;
  justify-content: space-around;
}
.container:global(.uui-calendar-container) :global(.uui-calendar-days) {
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
}
.container:global(.uui-calendar-container) :global(.uui-calendar-days) > * {
  display: flex;
}