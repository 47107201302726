.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-accordion-bg: var(--uui-surface-main);
  --uui-accordion-border: transparent;
  --uui-accordion-body-border: var(--uui-divider);
  --uui-accordion-toggler-border: var(--uui-divider);
  --uui-accordion-text: var(--uui-text-primary);
  --uui-accordion-text-disabled: var(--uui-text-disabled);
  --uui-accordion-icon: var(--uui-icon);
  --uui-accordion-icon-disabled: var(--uui-control-icon-disabled);
  --uui-accordion-shadow: var(--uui-shadow-level-1);
  --uui-accordion-shadow-hovered: var(--uui-shadow-level-2);
  --uui-accordion-border-radius: var(--uui-border-radius);
  --uui-accordion-toggler-font: var(--uui-font);
  --uui-accordion-toggler-font-weight: 600;
  --uui-accordion-body-font: var(--uui-font);
  --uui-accordion-body-font-weight: 400;
  --uui-accordion-border-width: 0;
  --uui-accordion-divider-width: 1px;
}
.root :global(.uui-accordion-toggler) {
  font-family: var(--uui-accordion-toggler-font);
  font-weight: var(--uui-accordion-toggler-font-weight);
  color: var(--uui-accordion-text);
  cursor: pointer;
}
.root :global(.uui-accordion-toggler):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
}
.root :global(.uui-accordion-body) {
  font-family: var(--uui-accordion-body-font);
  font-weight: var(--uui-accordion-body-font-weight);
  color: var(--uui-accordion-text);
}
.root:global(.uui-accordion-container).mode-block {
  box-shadow: var(--uui-accordion-shadow);
}
.root.mode-block {
  border-radius: var(--uui-accordion-border-radius);
  border: var(--uui-accordion-border-width) solid var(--uui-accordion-border);
}
.root.mode-block:hover, .root.mode-block:focus {
  box-shadow: var(--uui-accordion-shadow-hovered);
}
.root.mode-block :global(.uui-accordion-toggler) {
  background: var(--uui-accordion-bg);
  fill: var(--uui-accordion-icon);
  line-height: 24px;
  font-size: 18px;
  padding: 0 18px;
  border-radius: var(--uui-accordion-border-radius);
}
.root.mode-block :global(.uui-accordion-toggler) :global(.uui-icon) svg {
  height: 24px;
  width: 24px;
}
.root.mode-block :global(.uui-accordion-toggler) :global(.uui-accordion-toggle-container) {
  padding-top: 18px;
  padding-bottom: 18px;
}
.root.mode-block :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--uui-accordion-text);
}
.root.mode-block :global(.uui-accordion-body) {
  padding: 18px;
  line-height: 24px;
  letter-spacing: 0;
  background: var(--uui-accordion-bg);
  border-top: var(--uui-accordion-divider-width) solid var(--uui-accordion-body-border);
  border-radius: 0 0 var(--uui-accordion-border-radius) var(--uui-accordion-border-radius);
}
.root.mode-block:global(.uui-opened) :global(.uui-accordion-toggler) {
  border-radius: var(--uui-accordion-border-radius) var(--uui-accordion-border-radius) 0 0;
}
.root.mode-block:global(.uui-disabled) {
  pointer-events: none;
}
.root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler) {
  cursor: default;
  fill: var(--uui-accordion-icon-disabled);
}
.root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler):global(.uui-opened) {
  border-radius: var(--uui-accordion-border-radius) var(--uui-accordion-border-radius) 0 0;
}
.root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--uui-accordion-text-disabled);
}
.root.mode-inline :global(.uui-accordion-toggler) {
  border-bottom: var(--uui-accordion-divider-width) solid var(--uui-accordion-toggler-border);
  fill: var(--uui-accordion-icon);
  line-height: 18px;
  font-size: 14px;
  padding: 8px 12px 8px;
}
.root.mode-inline :global(.uui-accordion-toggler) :global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.mode-inline :global(.uui-accordion-toggler) :global(.uui-accordion-toggle-container) {
  min-height: 24px;
}
.root.mode-inline :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--uui-accordion-text);
}
.root.mode-inline :global(.uui-accordion-body) {
  padding: 1px 12px 12px;
  border-bottom: var(--uui-accordion-divider-width) solid var(--uui-accordion-toggler-border);
}
.root.mode-inline:global(.uui-opened) :global(.uui-accordion-toggler) {
  border-bottom: 0 none;
}
.root.mode-inline:global(.uui-disabled) :global(.uui-accordion-toggler) {
  cursor: default;
  fill: var(--uui-accordion-icon-disabled);
}
.root.mode-inline:global(.uui-disabled) :global(.uui-accordion-toggler):global(.uui-opened) {
  border-bottom: 0 none;
}
.root.mode-inline:global(.uui-disabled) :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--uui-accordion-text-disabled);
}
.root.padding-0 :global(.uui-accordion-toggler), .root.padding-0 :global(.uui-accordion-body) {
  padding-left: 0px;
  padding-right: 0px;
}
.root.padding-6 :global(.uui-accordion-toggler), .root.padding-6 :global(.uui-accordion-body) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.padding-12 :global(.uui-accordion-toggler), .root.padding-12 :global(.uui-accordion-body) {
  padding-left: 12px;
  padding-right: 12px;
}
.root.padding-18 :global(.uui-accordion-toggler), .root.padding-18 :global(.uui-accordion-body) {
  padding-left: 18px;
  padding-right: 18px;
}