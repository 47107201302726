.header {
  --uui-data-picker-header-close-icon-padding: 24px;
  --uui-data-picker-header-title-font-weight: 600;
  display: none;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 720px) {
  .header {
    display: flex;
  }
}
.header .title {
  --uui-text-font-weight: var(--uui-data-picker-header-title-font-weight);
}

.close {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  inset-inline-end: var(--uui-data-picker-header-close-icon-padding);
}