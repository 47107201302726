.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.container:global(.uui-input-box) {
  position: relative;
  display: flex;
  outline: none;
}
.container:global(.uui-input-box) :global(.uui-input) {
  -moz-appearance: textfield;
  flex: 1 1 auto;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
}
.container:global(.uui-input-box) :global(.uui-input)::-ms-clear {
  display: none;
}
.container:global(.uui-input-box) :global(.uui-input):invalid {
  box-shadow: none;
}
.container:global(.uui-input-box) :global(.uui-numeric-input-up-button), .container:global(.uui-input-box) :global(.uui-numeric-input-down-button) {
  user-select: none;
}
.container:global(.uui-input-box) :global(.uui-numeric-input-up-button):hover, .container:global(.uui-input-box) :global(.uui-numeric-input-down-button):hover {
  cursor: pointer;
}

.align-right {
  text-align: right;
}