@use "@epam/promo/assets/styles/index" as *;

.root {
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    // height: calc(100vh - 102px);
    overflow: auto;

    .navPage {
        margin: 0 auto 30px auto;
        padding: 10px;
        max-width: 1000px;

        .navTitle {
            font-family: var(--uui-font);
            font-weight: 600;
            color: var(--uui-text-primary);
            margin: 30px 0 5px;
            font-size: 36px;
            line-height: 48px;
        }

        .navCards {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            flex-wrap: wrap;
            max-width: 1000px;
            gap: 10px;
        }

        @media (max-width: 1280px) {
            width: fit-content;

            .navCards {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
        }
    }

    .secondaryNavigation {
        min-height: 48px;
        padding: 0 18px;

        .sourceLink {
            padding: 0 12px 0 6px;
        }
    }
}
