.dropdown-body {
  --uui-main_menu-text: var(--uui-neutral-5);
  --uui-main_menu-text-hover: var(--uui-neutral-5);
  --uui-main_menu-text-active: var(--uui-neutral-5);
  --uui-main_menu-active: var(--uui-neutral-90);
  --uui-main_menu-border-active: var(--uui-primary-50);
  --uui-main_menu-dropdown-bg: var(--uui-neutral-90);
  --uui-main_menu-dropdown-bg-hover: var(--uui-neutral-80);
  --uui-main_menu-dropdown-shadow: none;
  --uui-main_menu-dropdown-border-radius: none;
  --uui-main_menu-icon: var(--uui-neutral-5);
  --uui-main_menu-icon-hover: var(--uui-neutral-5);
  --uui-main_menu-icon-active: var(--uui-neutral-5);
  display: flex;
  flex-direction: column;
  min-width: 200px;
  background-color: var(--uui-main_menu-dropdown-bg);
  box-shadow: var(--uui-main_menu-dropdown-shadow);
  border-radius: var(--uui-main_menu-dropdown-border-radius);
  overflow: hidden;
}
.dropdown-body :global(.-clickable):global(.uui-button-box):hover {
  background-color: var(--uui-main_menu-dropdown-bg-hover);
}
.dropdown-body :global(.-clickable):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
}
.dropdown-body :global(.uui-button-box):global(.uui-active) {
  border: 0 none;
  border-left: 3px solid var(--uui-main_menu-border-active);
  background-color: var(--uui-main_menu-hover);
}