.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.container:focus-within {
  outline: none;
}
.container input[type=checkbox] {
  margin: 0;
  border: 0 none;
  width: 0;
  height: 0;
  appearance: none;
}
.container input[type=checkbox]:focus {
  outline: none;
}
.container :global(.uui-switch-toggler) {
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  position: relative;
}
.container :global(.uui-switch-body) {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}