.search-input {
  --uui-burger_search: var(--uui-neutral-5);
  --uui-burger_search-fill: var(--uui-text-disabled);
  --uui-burger_search-bg: var(--uui-neutral-80);
  --uui-burger_search-placeholder: var(--uui-text-disabled);
  --uui-burger_search-font: var(--uui-font);
  --uui-burger_search-font-weight: 400;
  height: 60px;
  background-color: var(--uui-burger_search-bg);
  fill: var(--uui-burger_search-fill);
  padding: 0 24px;
  flex-shrink: 0;
  width: auto;
}
.search-input :global(.uui-input) {
  color: var(--uui-burger_search);
  font-family: var(--uui-burger_search-font);
  font-weight: var(--uui-burger_search-font-weight);
}
.search-input :global(.uui-input)::placeholder {
  color: var(--uui-burger_search-placeholder);
}
.search-input :global(.uui-caption),
.search-input :global(.uui-input) {
  padding: 18px 16px;
  line-height: 24px;
  font-size: 14px;
}
.search-input :global(.uui-icon) svg {
  height: 16px;
  max-width: 16px;
}
.search-input :global(.uui-icon-cancel) svg {
  height: 24px;
  max-width: 24px;
}
.search-input :global(.uui-icon-cancel):hover {
  cursor: pointer;
}