.container {
  display: flex;
  isolation: isolate;
}
.container > * {
  margin-right: -1px !important;
}
.container > *:focus {
  box-shadow: none;
}
.container .uui-focus, .container > :focus-within, .container > :hover {
  position: relative;
  z-index: 1;
}