.search-input {
  flex-basis: 200px;
  max-width: 500px;
  height: 36px;
  min-width: 36px;
  border-radius: 18px;
  background-color: var(--uui-main_menu-search-bg);
  fill: var(--uui-main_menu-search-icon);
  padding: 0 6px;
  margin: 0 12px;
  transition: background-color 0.3s ease-out, flex-basis 0.3s ease-in-out;
}
.search-input :global(.uui-input) {
  color: var(--uui-main_menu-search-text);
  font-family: var(--uui-main_menu-search-font);
  font-weight: var(--uui-main_menu-search-font-weight, normal);
}
.search-input :global(.uui-input)::placeholder {
  color: var(--uui-main_menu-search-placeholder);
}
.search-input :global(.uui-caption),
.search-input :global(.uui-input) {
  padding: 8px 6px;
  line-height: 18px;
  font-size: 14px;
}
.search-input :global(.uui-icon) svg {
  height: 16px;
  max-width: 16px;
}
.search-input :global(.uui-icon-cancel) svg {
  height: 24px;
  max-width: 24px;
}
.search-input :global(.uui-icon-cancel):hover {
  cursor: pointer;
}
.search-input:global(.uui-input-box) {
  min-height: 36px;
}