.root:global(.uui-slider-container) {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  cursor: pointer;
}
.root:global(.uui-slider-container) :global(.uui-slider-handle) {
  position: absolute;
  left: 0;
}
.root:global(.uui-slider-container) :global(.uui-slider-handle):focus {
  outline-offset: 1px;
  outline-color: var(--uui-color-700);
}
.root:global(.uui-slider-container) :global(.uui-slider) {
  pointer-events: none;
}
.root:global(.uui-slider-container) :global(.uui-slider-filled) {
  position: absolute;
  pointer-events: none;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale) {
  position: absolute;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale-number) {
  position: absolute;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale-dot) {
  position: absolute;
}