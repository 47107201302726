@use "~@epam/promo/assets/styles/fonts" as *;
@use "~@epam/promo/assets/styles/colors" as *;

@keyframes showInfoPanel {
    0% {
        transform: translate3d(100%, 0, 0);
        margin-left: -360px;
    }

    100% {
        transform: translate3d(0, 0, 0);
        margin-left: 0;
    }
}

@keyframes hideInfoPanel {
    0% {
        transform: translate3d(0, 0, 0);
        margin-left: 0;
    }

    100% {
        transform: translate3d(100%, 0, 0);
        margin-left: -360px;
    }
}

body {
    &:global(.full-screen) {
        .wrapper {
            height: 100vh;
        }
    }
}

.wrapper {
    display: flex;
    height: calc(100vh - 102px);
    width: 360px;
    border-left: 1px solid var(--uui-divider);

    .no-wrap {
        white-space: nowrap;
    }
}

.infoSidebarPanelWrapper {
    width: 360px;
    margin-left: -360px;

    &:global(.show) {
        animation: showInfoPanel 500ms;
        animation-fill-mode: forwards;
    }

    &:global(.hide) {
        animation: hideInfoPanel 500ms;
        animation-fill-mode: forwards;
    }
}
