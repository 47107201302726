.container :global(.uui-accordion-toggle-container) {
  display: flex;
  align-items: center;
}
.container :global(.uui-accordion-toggler):focus {
  outline: none;
}
.container :global(.uui-accordion-title) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  margin-left: auto;
}