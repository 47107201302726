.root {
  --uui-pagination-font: var(--uui-font);
  --uui-pagination-font-weight: 400;
  display: flex;
  flex-direction: row;
}
.root .page {
  --uui-btn-padding: 1px;
}
.root :global(.uui-caption) {
  font-family: var(--uui-pagination-font);
  font-weight: var(--uui-pagination-font-weight, normal);
  font-size: 14px;
}
.root > :first-child {
  margin-inline-end: var(--uui-horizontal-gap);
}
.root > :last-child {
  margin-inline-start: var(--uui-horizontal-gap);
}
.root .spacer {
  color: var(--uui-text-primary);
}
.root .mode-ghost {
  color: var(--uui-text-primary);
}
.root .mode-ghost:hover {
  color: var(--uui-primary-50);
}