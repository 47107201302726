.contentWrapper {
    overflow: hidden;
    display: flex;
    position: relative;

    .contentContainer {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 96px);
        transition: all 500ms;
        flex-basis: auto;
        min-width: 0;
        flex-grow: 1;

        :global(.uui-table-row) {
            .detailedIcon {
                visibility: hidden;
            }

            &:hover {
                .detailedIcon {
                    visibility: visible;
                }
            }
        }
    }
}

.headerPanel {
    background-image: url("../../icons/header_1.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    font-family: "Century Gothic", Arial, Helvetica, sans-serif;
    font-weight: bold;

    .headerPanelWrapper {
        padding-left: 10px;
        padding-right: 10px;
        margin-left: auto;
        margin-right: auto;

        .header {
            width: 384px;
            box-sizing: border-box;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.4);

            .headerText {
                color: white;
                line-height: 50px;
                display: inline-block;
                font-size: 1.3rem;
                margin: 0;
                padding: 0 10px;
            }

            .addButtonWrap {
                height: 50px;
                display: inline-block;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
                float: right;
                display: flex;
                flex: 0 0 auto;
                justify-content: space-around;

                .addButton {
                    color: #fff;
                    line-height: 50px;
                    cursor: pointer;
                    padding: 0 14px;
                }
            }
        }
    }

    .headerTitle {
        margin-left: 10px;
        font-family: "Century Gothic", "Arial Narrow", Arial, sans-serif;
        color: white;
        font-weight: bold;
    }
}
