.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: var(--uui-focus-radius);
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-text_area-bg: var(--uui-control-bg);
  --uui-text_area-bg-disabled: var(--uui-control-bg-disabled);
  --uui-text_area-border: var(--uui-control-border);
  --uui-text_area-border-hover: var(--uui-control-border-hover);
  --uui-text_area-border-active: var(--uui-control-border-focus);
  --uui-text_area-border-error: var(--uui-error-50);
  --uui-text_area-border-disabled: var(--uui-control-border-disabled);
  --uui-text_area-placeholder: var(--uui-control-placeholder);
  --uui-text_area-placeholder-disabled: var(--uui-control-placeholder-disabled);
  --uui-text_area-placeholder-readonly: var(--uui-control-placeholder-disabled);
  --uui-text_area-text: var(--uui-control-text);
  --uui-text_area-text-disabled: var(--uui-control-text-disabled);
  --uui-text_area-border-radius: var(--uui-border-radius);
  --uui-text_area-font: var(--uui-font);
  --uui-text_area-font-weight: 400;
  --uui-text_area-min-height: var(--uui-size);
  --uui-text_area-min-width: var(--uui-size);
  --uui-text_area-border-width: var(--uui-border-width);
  --uui-text_area-horizontal-padding: var(--uui-horizontal-padding);
  --uui-text_area-inline-horizontal-padding: 5px;
  --uui-text_area-vertical-padding: calc((var(--uui-text_area-min-height) - var(--uui-text_area-line-height) - (var(--uui-text_area-border-width) * 2)) / 2);
  --uui-text_area-icon-height: var(--uui-icon-size);
  --uui-text_area-line-height: var(--uui-line-height);
  --uui-text_area-font-size: var(--uui-font-size);
  min-width: var(--uui-text_area-min-width);
  min-height: var(--uui-text_area-min-height);
}
.root :global(.uui-input) {
  min-height: var(--uui-text_area-min-height);
  line-height: var(--uui-text_area-line-height);
  font-size: var(--uui-text_area-font-size);
  padding-top: var(--uui-text_area-vertical-padding);
  padding-bottom: var(--uui-text_area-vertical-padding);
  padding-inline-start: calc(var(--uui-text_area-horizontal-padding) - var(--uui-text_area-border-width));
  padding-inline-end: calc(var(--uui-text_area-horizontal-padding) * 1.5 - var(--uui-text_area-border-width));
  color: var(--uui-text_area-text);
  border-style: solid;
  border-radius: var(--uui-text_area-border-radius);
  font-family: var(--uui-text_area-font);
  font-weight: var(--uui-text_area-font-weight);
  box-sizing: border-box;
  overflow-x: hidden;
}
.root :global(.uui-input)::placeholder {
  color: var(--uui-text_area-placeholder);
}
.root :global(.uui-input):placeholder-shown {
  text-overflow: ellipsis;
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-input):global(.uui-readonly) {
  color: var(--uui-text_area-text);
}
.root :global(.uui-input):global(.uui-readonly)::placeholder {
  color: var(--uui-text_area-placeholder-readonly);
}
.root :global(.uui-input):global(.uui-disabled) {
  color: var(--uui-text_area-text-disabled);
}
.root :global(.uui-input):global(.uui-disabled)::placeholder {
  color: var(--uui-text_area-placeholder-disabled);
}
.root.mode-form :global(.uui-input) {
  background-color: var(--uui-text_area-bg);
  border-color: var(--uui-text_area-border);
}
.root.mode-form :global(.uui-input):hover {
  border-color: var(--uui-text_area-border-hover);
}
.root.mode-form :global(.uui-input):global(.uui-focus) {
  border-color: var(--uui-text_area-border-active);
}
.root.mode-form :global(.uui-input):global(.uui-invalid) {
  border-color: var(--uui-text_area-border-error);
}
.root.mode-form :global(.uui-input):global(.uui-readonly) {
  background-color: var(--uui-text_area-bg-disabled);
  border-color: var(--uui-text_area-border-disabled);
}
.root.mode-form :global(.uui-input):global(.uui-disabled) {
  background-color: var(--uui-text_area-bg-disabled);
  border-color: var(--uui-text_area-border-disabled);
}
.root.mode-cell :global(.uui-input) {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.uui-input):global(.uui-readonly) {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.uui-input):global(.uui-disabled) {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-inline :global(.uui-input) {
  padding-left: var(--uui-text_area-inline-horizontal-padding);
  padding-right: var(--uui-text_area-inline-horizontal-padding);
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-radius: var(--uui-text_area-border-radius);
}
.root.mode-inline :global(.uui-input):hover {
  border-color: var(--uui-text_area-border-hover);
}
.root.mode-inline :global(.uui-input):global(.uui-focus) {
  border-color: var(--uui-text_area-border-active);
}
.root.mode-inline :global(.uui-input):global(.uui-invalid) {
  border-color: var(--uui-text_area-border-error);
}
.root.mode-inline :global(.uui-input):global(.uui-readonly):hover {
  border-color: transparent;
}
.root.mode-inline :global(.uui-input):global(.uui-readonly):global(.uui-focus) {
  border-color: transparent;
  box-shadow: none;
}
.root.mode-inline :global(.uui-input):global(.uui-readonly)::placeholder {
  color: var(--uui-text_area-placeholder-readonly);
}
.root.mode-inline :global(.uui-input):global(.uui-disabled) {
  background-color: transparent;
  color: var(--uui-text_area-text-disabled);
  border-color: transparent;
}
.root.mode-inline :global(.uui-input):global(.uui-disabled):hover, .root.mode-inline :global(.uui-input):global(.uui-disabled):active, .root.mode-inline :global(.uui-input):global(.uui-disabled):focus {
  background-color: transparent;
  color: var(--uui-text_area-text-disabled);
  border-color: transparent;
  box-shadow: none;
}
.root.mode-inline :global(.uui-input):global(.uui-disabled)::placeholder {
  color: var(--uui-text_area-placeholder-disabled);
}