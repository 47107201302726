@use "@epam/promo/assets/styles/colors.scss" as *;

.layout {
    display: flex;
    background-color: transparent;
    flex-shrink: 0;

    .footer {
        max-width: 100%;
        overflow-x: auto;
        width: 100%;
        margin: 0 auto;

        .copyright {
            padding: 0 10px;
        }
    }
}
