.container:focus {
  outline: none;
}
.container :global(.uui-rating-empty-star-container) {
  display: flex;
}
.container :global(.uui-rating-filled-star-container) {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.container :global(.uui-rating-star) {
  flex-shrink: 0;
}