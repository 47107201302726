.root {
  --uui-dropdown_container-bg: var(--uui-surface-main);
  --uui-dropdown_container-shadow: var(--uui-shadow-level-2);
  --uui-dropdown_container-border-radius: 6px;
}
.root:global(.uui-dropdown-body) {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-sizing: border-box;
  box-shadow: var(--uui-dropdown_container-shadow);
  background-color: var(--uui-dropdown_container-bg);
  border: 0;
  border-radius: var(--uui-dropdown_container-border-radius);
  overflow: hidden;
}
@media (max-width: 720px) {
  .root:global(.uui-dropdown-body) {
    border-radius: 0;
  }
}
.root:global(.uui-dropdown-body):focus-visible {
  outline: none;
}
.root :global(.uui-popover-arrow) {
  position: absolute;
  width: 12px;
  height: 12px;
  overflow: hidden;
}
.root :global(.uui-popover-arrow)::after {
  content: "";
  position: absolute;
  background: var(--uui-dropdown_container-bg);
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.root:global(.padding-6) {
  padding-left: 6px;
  padding-right: 6px;
}
.root:global(.padding-12) {
  padding-left: 12px;
  padding-right: 12px;
}
.root:global(.padding-18) {
  padding-left: 18px;
  padding-right: 18px;
}
.root:global(.padding-24) {
  padding-left: 24px;
  padding-right: 24px;
}
.root:global(.padding-30) {
  padding-left: 30px;
  padding-right: 30px;
}
.root:global(.vPadding-6) {
  padding-top: 3px;
  padding-bottom: 3px;
}
.root:global(.vPadding-12) {
  padding-top: 6px;
  padding-bottom: 6px;
}
.root:global(.vPadding-18) {
  padding-top: 9px;
  padding-bottom: 9px;
}
.root:global(.vPadding-24) {
  padding-top: 12px;
  padding-bottom: 12px;
}
.root:global(.vPadding-30) {
  padding-top: 15px;
  padding-bottom: 15px;
}
.root:global(.vPadding-48) {
  padding-top: 24px;
  padding-bottom: 24px;
}