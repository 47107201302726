.container {
  position: relative;
  display: flex;
}

.container-content.burger-transition-enter {
  opacity: 0;
}
.container-content.burger-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.container-content.burger-transition-exit {
  opacity: 1;
}
.container-content.burger-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}