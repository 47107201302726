.root > :nth-child(n) {
  border-radius: 0;
}
.root > :first-child {
  border-top-left-radius: var(--uui-border-radius);
  border-bottom-left-radius: var(--uui-border-radius);
}
.root > :last-child {
  border-top-right-radius: var(--uui-border-radius);
  border-bottom-right-radius: var(--uui-border-radius);
}

*[dir=rtl] .root > :first-child, .root[dir=rtl] > :first-child {
  border-radius: 0 var(--uui-border-radius) var(--uui-border-radius) 0;
}

*[dir=rtl] .root > :last-child, .root[dir=rtl] > :last-child {
  border-radius: var(--uui-border-radius) 0 0 var(--uui-border-radius);
}