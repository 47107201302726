/* stylelint-disable-next-line */
[data-placement^=right] :global(.uui-popover-arrow) {
  right: 100%;
  margin-right: -1px;
}
[data-placement^=right] :global(.uui-popover-arrow)::after {
  transform: translateX(calc(100% - 1px)) translateY(20%) rotate(45deg);
}

[data-placement^=left] :global(.uui-popover-arrow) {
  left: 100%;
  margin-left: -1px;
}
[data-placement^=left] :global(.uui-popover-arrow)::after {
  transform: translateX(calc(-50% + 1px)) translateY(20%) rotate(45deg);
}

[data-placement^=top] :global(.uui-popover-arrow) {
  top: 100%;
  margin-top: -1px;
}
[data-placement^=top] :global(.uui-popover-arrow)::after {
  transform: translateX(30%) translateY(calc(-50% + 1px)) rotate(45deg);
}

[data-placement^=bottom] :global(.uui-popover-arrow) {
  bottom: 100%;
  margin-bottom: -1px;
}
[data-placement^=bottom] :global(.uui-popover-arrow)::after {
  transform: translateX(30%) translateY(calc(100% - 1px)) rotate(45deg);
}