@use "@epam/promo/assets/styles/colors.scss" as *;

.root {
    // background: var(--uui-app-bg);
    // background: var(--white);
    color: var(--uui-text-primary);
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1 0 auto;
    }

    .error-block {
        flex-grow: 1;
    }
}

.rootNonUser {
    background: var(--white);
    background-image: url("../icons/login-background.png");
    background-repeat: no-repeat;
    background-size: cover;
}
