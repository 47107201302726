.root {
  --uui-dt-scroll-shadow-bottom-offset: -5px;
  --uui-dt-scroll-shadow-height: 5px;
  --uui-dt-scroll-shadow-background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  --uui-dt-scroll-shadow-transition: 200ms;
  --uui-dt-sticky-header-top: 0;
  --uui-dt-sticky-header-z-index: 5;
  --uui-dt-no-results-margin-top: 30px;
  background: var(--uui-dt-bg);
}
.root:global(.-scrolled-left) :global(.uui-scroll-shadow-left) {
  opacity: 1;
}
.root:global(.-scrolled-right) :global(.uui-scroll-shadow-right) {
  opacity: 1;
}
.root :global(.uui-scroll-shadow-top) {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: var(--uui-dt-scroll-shadow-bottom-offset);
}
.root :global(.uui-scroll-shadow-top-visible) {
  display: block;
  height: var(--uui-dt-scroll-shadow-height);
  background: var(--uui-dt-top-shadow, var(--uui-dt-scroll-shadow-background));
  transition: display var(--uui-dt-scroll-shadow-transition);
}

.sticky-header {
  position: sticky;
  top: var(--uui-dt-sticky-header-top);
  isolation: isolate;
  z-index: var(--uui-dt-sticky-header-z-index);
}

.no-results {
  position: sticky;
  left: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-results .icon {
  align-items: center;
}
.no-results .title {
  padding: 0;
  margin-top: var(--uui-dt-no-results-margin-top);
}