.root {
  --uui-status_indicator-bg: var(--uui-color-50);
  --uui-status_indicator-caption: var(--uui-text-primary);
  --uui-status_indicator-font-family: var(--uui-font);
  --uui-status_indicator-font-weight: 400;
  --uui-status_indicator-border-width: var(--uui-border-width);
  --uui-status_indicator-horizontal-gap: var(--uui-horizontal-gap);
  --uui-status_indicator-dot-size: var(--uui-dot-size);
  --uui-status_indicator-line-height: var(--uui-line-height);
  --uui-status_indicator-font-size: var(--uui-font-size);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.root :global(.uui-status_indicator-dot) {
  box-sizing: border-box;
  border-radius: 50%;
  background-color: var(--uui-status_indicator-bg);
  margin-inline-end: var(--uui-status_indicator-horizontal-gap);
  width: var(--uui-status_indicator-dot-size);
  height: var(--uui-status_indicator-dot-size);
}
.root :global(.uui-status_indicator-caption) {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-family: var(--uui-status_indicator-font-family);
  font-weight: var(--uui-status_indicator-font-weight);
  font-size: var(--uui-status_indicator-font-size);
  line-height: var(--uui-status_indicator-line-height);
  color: var(--uui-status_indicator-caption);
}
.root:global(.uui-color-neutral) :global(.uui-status_indicator-dot) {
  --uui-status_indicator-bg: var(--uui-neutral-60);
}
.root:global(.uui-fill-outline) :global(.uui-status_indicator-dot) {
  background-color: color-mix(in srgb, var(--uui-status_indicator-bg) 20%, transparent);
  border: var(--uui-status_indicator-border-width) solid var(--uui-status_indicator-bg);
}