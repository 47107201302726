.root {
  --uui-text: var(--uui-color-50);
  --uui-text-size: var(--uui-size);
  --uui-text-font: var(--uui-font);
  --uui-text-font-weight: 400;
  --uui-text-font-style: normal;
  --uui-text-vertical-padding: calc((var(--uui-text-size) - var(--uui-text-line-height, var(--uui-line-height))) / 2);
  font-family: var(--uui-text-font);
  font-weight: var(--uui-text-font-weight);
  font-style: var(--uui-text-font-style);
  color: var(--uui-text);
  padding-top: var(--uui-text-vertical-padding);
  padding-bottom: var(--uui-text-vertical-padding);
}
.root.line-height {
  line-height: var(--uui-text-line-height, var(--uui-line-height));
}
.root.font-size {
  font-size: var(--uui-text-font-size, var(--uui-font-size));
}
.root:global.uui-size-none {
  --uui-text-size: none;
}
.root:global.uui-text.uui-color-white {
  --uui-text: var(--uui-neutral-0);
}
.root:global.uui-text.uui-color-primary {
  --uui-text: var(--uui-text-primary);
}
.root:global.uui-text.uui-color-secondary {
  --uui-text: var(--uui-text-secondary);
}
.root:global.uui-text.uui-color-tertiary {
  --uui-text: var(--uui-text-tertiary);
}
.root:global.uui-text.uui-color-disabled {
  --uui-text: var(--uui-text-disabled);
}
.root:global.uui-text.uui-color-info {
  --uui-text: var(--uui-text-info);
}
.root:global.uui-text.uui-color-warning {
  --uui-text: var(--uui-text-warning);
}
.root:global.uui-text.uui-color-critical {
  --uui-text: var(--uui-text-critical);
}
.root:global.uui-text.uui-color-success {
  --uui-text: var(--uui-text-success);
}
.root:global.uui-font-weight-200 {
  --uui-text-font-weight: 200;
}
.root:global.uui-font-weight-300 {
  --uui-text-font-weight: 300;
}
.root:global.uui-font-weight-400 {
  --uui-text-font-weight: 400;
}
.root:global.uui-font-weight-600 {
  --uui-text-font-weight: 600;
}
.root:global.uui-font-weight-700 {
  --uui-text-font-weight: 700;
}
.root:global.uui-font-weight-900 {
  --uui-text-font-weight: 900;
}
.root:global.uui-font-style-normal {
  --uui-text-font-style: normal;
}
.root:global.uui-font-style-italic {
  --uui-text-font-style: italic;
}